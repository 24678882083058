* {
	font-family: Lato;
}

#container h1 { 
	font-size: 35px;
	font-weight: bold;
	border-bottom: 2px solid #2050AA;
	margin-bottom: 0px;
}
	#container h1 span {
		font-size: 0.9em;
	}

	#container div.row {
		padding-top: 14px;
	}

#container h2 {
	font-size: 25px;
}

span.dashboard-footer-block {
	border: 1px solid #afafaf;
	font-family: monospace;
	font-size: 0.8em;
}
