/*Navbar manipulation*/
.navbar {
	margin-bottom: 0px !important;
}

/* main body */
body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

body>#menu {
	order: 1;
	flex-grow: 0;
}

body>#page_content {
	order: 2;
	display: flex;
	flex: 1;
}

body>#footer {
	order: 3;
	text-align: center;
	background: antiquewhite;
}

#page_content>#container {
	order: 2;
	flex: 1;
	text-align: center;
}

/* sidebar */
.w3-sidebar .w3-bar-item {
	width: 100%;
}

#page_content>#sidebar {
	flex-basis: 200px;
	order: 1;
}

.dashboard-sidebar-item {
	text-align: left;
}

.dashboard-sidebar-item:hover {
	text-decoration: none;
	border-right: 2px solid #337ab7;
}

.dashboard-sidebar-item:focus {
	border-right: 2px solid #337ab7;
	background-color: #ccc !important;
}

.dashboard-sidebar-item span.fad {
	font-size: 18px;
	padding: 0 2px;
}

.dashboard-sidebar-subitem {
	text-align: left;
	font-size: 0.7em !important;
	padding: 2px 26px;
	margin: -14px 0px 0px 0px;
}

.dashboard-sidebar-subitem:hover {
	border-right: 1px solid #337ab7;
}

/* Vehicle info section &  Map info section */
.mwc-heading {
	font-weight: bold;
}

.mwc-body {}

.mwc-section {}
